<template>
  <div>
    <v-main class="pt-0">
      <v-container fluid class="h-full">
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
    <notifications group="notify-group" width="500" position="top center" />
  </div>
</template>
<script>
export default {
  name: 'GlobalLayout',
  data () {
    return {}
  }
}
</script>
